import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@strava/ui/Button';

import ConfirmModal from '../../../../../../components/ConfirmModal/ConfirmModal';

const ConfirmButton = ({
  modalTitle,
  btnTitle,
  modalBody,
  onClick,
  onConfirm,
  disabled = false
}) => {
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpenModal(true);
          onClick();
        }}
        type="button"
        disabled={disabled}
      >
        {btnTitle}
      </Button>
      <ConfirmModal
        isOpen={openModal}
        title={modalTitle}
        body={modalBody}
        onConfirm={onConfirm}
        onDismiss={closeModal}
      />
    </>
  );
};

ConfirmButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  btnTitle: PropTypes.string.isRequired,
  modalBody: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default ConfirmButton;
