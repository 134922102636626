import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DropdownSelect from '@strava/ui/DropdownSelect';
import Button from '@strava/ui/Button';
import I18n from 'utils/I18n';
import createNetworkingClient from 'utils/networking-client';
import { trackV2 } from 'utils/analytics';
import ConfirmButton from './components/ConfirmButton';

import PrivateLocationMap from './components/PrivateLocationMap';
import AddressTypeAhead from './components/AddressTypeAhead';
import styles from './styles.scss';

function HideAddressApp({
  radiiOptions,
  privateLocations,
  createPrivateLocationPath,
  isAthleteUnder18
}) {
  const I18nPrefix = 'strava.settings.privacy.hide_address.';
  const distanceUnits =
    I18n.measurementUnits() === 'metric' ? 'meters' : 'miles';
  const [selectedRadius, setSelectedRadius] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [error, setError] = useState('');

  const trackEvent = (action, element = null, properties = null) => {
    trackV2({
      category: 'privacy_settings',
      page: 'private_locations',
      action,
      ...(element && { element }),
      ...(properties && { properties })
    });
  };
  const trackDelete = (p) => {
    trackEvent('click', 'delete', {
      private_location_id: p.id
    });
  };

  const trackReposition = (p) => {
    trackEvent('click', 'reposition', {
      private_location_id: p.id
    });
  };

  useEffect(() => {
    trackEvent('screen_enter', null, {
      private_location_ids: privateLocations.map((p) => p.id)
    });
  }, []);

  const createPrivateLocation = (e) => {
    e.preventDefault();

    if (!selectedAddress) {
      setError('address_error');
    } else if (!selectedRadius) {
      setError('radius_error');
    } else {
      createNetworkingClient()
        .post(createPrivateLocationPath, {
          radius: selectedRadius,
          lat_lng: selectedAddress.center.reverse().join(','),
          address: selectedAddress.place_name
        })
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          if (err.response.data.is_duplicate_error) {
            setError('duplicate_error_v2');
          } else {
            setError('generic_error');
          }
        });
    }
  };

  const deleteHiddenLocation = (p) => {
    trackEvent('click', 'confirm_delete', { private_location_id: p.id });
    createNetworkingClient()
      .delete(p.deletePrivateLocationPath)
      .then(() => {
        window.location.reload();
      });
  };

  const repositionHiddenLocation = (p) => {
    trackEvent('click', 'confirm_reposition', { private_location_id: p.id });
    createNetworkingClient()
      .put(p.regeneratePrivateLocationPath)
      .then(() => {
        window.location.reload();
      });
  };

  const buildMaps = () => {
    if (privateLocations && privateLocations.length) {
      return (
        <div>
          {privateLocations.map((p) => (
            <div className={styles.mapContainer} key={p.id}>
              <PrivateLocationMap privateLocation={p} />
              <div>
                <div className={styles.address}>{p.address}</div>
                <div className={styles.radiusHidden}>
                  {I18n.t_html(p.distanceHidden.unit_key, {
                    distance: I18n.t(p.distanceHidden.value_key)
                  })}
                </div>
                <div className={styles.mapButtons}>
                  <ConfirmButton
                    modalTitle={I18n.t(`${I18nPrefix}delete_address_confirm`)}
                    btnTitle={I18n.t(`${I18nPrefix}delete_address`)}
                    modalBody={
                      isAthleteUnder18
                        ? I18n.t(`${I18nPrefix}delete_address_warning_under_18`)
                        : I18n.t(`${I18nPrefix}delete_address_warning_v2`)
                    }
                    onClick={() => trackDelete(p)}
                    onConfirm={() => deleteHiddenLocation(p)}
                  />
                  <ConfirmButton
                    modalTitle={I18n.t(`${I18nPrefix}reposition`)}
                    btnTitle={I18n.t(`${I18nPrefix}reposition`)}
                    modalBody={I18n.t(`${I18nPrefix}reposition_warning_v2`)}
                    onClick={() => trackReposition(p)}
                    onConfirm={() => repositionHiddenLocation(p)}
                    disabled={p.repositionRateLimited}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className={styles.reposition}>
            {I18n.t(`${I18nPrefix}reposition_explanation_v2`)}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.noAddresses}>
        {I18n.t(`${I18nPrefix}no_addresses`)}
      </div>
    );
  };

  return (
    <div>
      <div className={styles.detail}>
        {I18n.t_html(
          `${I18nPrefix}detail_v2_html`,
          {},
          {
            onLinkClick: () =>
              trackEvent('click', 'learn_more', { article_id: 115000173384 })
          }
        )}
      </div>
      <form className={styles.form} onSubmit={createPrivateLocation}>
        <AddressTypeAhead
          handleSelectedResult={(a) => setSelectedAddress(a.result)}
          clearSelectedResult={() => setSelectedAddress(null)}
        />
        <DropdownSelect
          className={styles.dropdown}
          options={radiiOptions.map((r) => ({
            value: r.value,
            label: I18n.t(`strava.settings.privacy.distance.${distanceUnits}`, {
              distance: I18n.t(r.label_key)
            })
          }))}
          placeholder={I18n.t(`${I18nPrefix}select_radius`)}
          onChange={(r) => {
            setSelectedRadius(r.value);
            trackEvent('click', 'select_radius', { distance: r.value });
          }}
          onMenuOpen={() => trackEvent('click', 'select_radius')}
        />
        <Button
          type="submit"
          className={styles.submitButton}
          variant="primary"
          onClick={() =>
            trackEvent('click', 'save', { distance: selectedRadius })
          }
        >
          {I18n.t(`${I18nPrefix}save_address`)}
        </Button>
      </form>
      {error ? (
        <div className={styles.error}>{I18n.t(`${I18nPrefix}${error}`)}</div>
      ) : null}
      <div>
        <div className={styles.yourAddresses}>
          {I18n.t(`${I18nPrefix}your_addresses`)}
        </div>
        <div>{buildMaps()}</div>
      </div>
    </div>
  );
}

HideAddressApp.propTypes = {
  radiiOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label_key: PropTypes.string
    })
  ).isRequired,
  privateLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      address: PropTypes.string,
      position: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
      }),
      original_position: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
      }),
      radius: PropTypes.number,
      repositionRateLimited: PropTypes.bool,
      regeneratePrivateLocationPath: PropTypes.string,
      deletePrivateLocationPath: PropTypes.string,
      distanceHidden: PropTypes.shape({
        unit_key: PropTypes.string,
        value_key: PropTypes.string
      })
    })
  ).isRequired,
  createPrivateLocationPath: PropTypes.string.isRequired,
  isAthleteUnder18: PropTypes.bool.isRequired
};

export default HideAddressApp;
