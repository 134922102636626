import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalTitle, ModalBody, ModalActions } from '@strava/ui/Modal';
import Button from '@strava/ui/Button';
import I18n from 'utils/I18n';

import styles from './ConfirmModal.scss';

const ConfirmModal = ({ onDismiss, onConfirm, isOpen, title, body }) => {
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      hasClose={true}
      aria-labelledby="modal-title"
      className={styles.confirmModal}
    >
      <ModalTitle className={styles.title} id="modal-title">
        {title}
      </ModalTitle>
      <ModalBody className={styles.body}>{body}</ModalBody>
      <ModalActions className={styles.actions}>
        <Button
          type="button"
          onClick={onDismiss}
          variant="text"
          className={styles.btn}
        >
          {I18n.t('components.button_cta_with_cancel.cancel')}
        </Button>
        <Button
          type="button"
          onClick={onConfirm}
          className={styles.btn}
          variant="primary"
        >
          {I18n.t('components.button_cta_with_cancel.confirm')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
};

export default ConfirmModal;
