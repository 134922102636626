import React from 'react';
import PropTypes from 'prop-types';

import Geocoder from '@strava/ui/Map/Geocoder';
import I18n from 'utils/I18n';

import styles from './styles.scss';

function AddressTypeAhead({ handleSelectedResult, clearSelectedResult }) {
  return (
    <div className={styles.typeahead}>
      <Geocoder
        placeholder={I18n.t(
          'strava.settings.privacy.hide_address.address_placeholder'
        )}
        onResult={handleSelectedResult}
        language={I18n.language()}
        options={{ types: 'address' }}
        onClear={clearSelectedResult}
        onLoading={clearSelectedResult}
      />
    </div>
  );
}

AddressTypeAhead.propTypes = {
  handleSelectedResult: PropTypes.func.isRequired,
  clearSelectedResult: PropTypes.func.isRequired
};

export default AddressTypeAhead;
