import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Map, { useMap } from '@strava/ui/Map';
import PolygonCircle from '@strava/ui/Map/PolygonCircle';
import Popup from '@strava/ui/Map/Popup';
import SvgActionsVisibleDisabledNormalXsmall from '@strava/icons/ActionsVisibleDisabledNormalXsmall';

import { trackV2 } from 'utils/analytics';
import styles from './styles.scss';

function MapAnalytics({ privateLocationId }) {
  const map = useMap();

  useEffect(() => {
    map.on('zoomstart', () => {
      trackV2({
        category: 'privacy_settings',
        page: 'private_locations',
        action: 'click',
        element: 'map_zoom',
        properties: { private_location_id: privateLocationId }
      });
    });
    map.on('dragstart', () => {
      trackV2({
        category: 'privacy_settings',
        page: 'private_locations',
        action: 'pan',
        element: 'map',
        properties: { private_location_id: privateLocationId }
      });
    });
  }, []);

  return null;
}

function PrivateLocationMap({ privateLocation }) {
  const setZoom = () => {
    let zoom;
    if (privateLocation.radius > 800) {
      zoom = 11;
    } else if (privateLocation.radius > 400) {
      zoom = 12;
    } else if (privateLocation.radius > 200) {
      zoom = 13;
    } else {
      zoom = 14;
    }
    return zoom;
  };

  return (
    <Map
      options={{
        center: [privateLocation.position.lng, privateLocation.position.lat],
        zoom: setZoom()
      }}
      attributionControl={true}
      className={styles.map}
    >
      <PolygonCircle
        id={privateLocation.id}
        lat={privateLocation.position.lat}
        lng={privateLocation.position.lng}
        // radius is in meters and we need to convert it to kilometers
        radius={privateLocation.radius / 1000}
        style={{
          fillColor: '#B2AFC0',
          fillOpacity: 0.7,
          strokeWeight: 0
        }}
      />

      <Popup
        latLng={[
          privateLocation.original_position.lat,
          privateLocation.original_position.lng
        ]}
        closeButton={false}
        closeOnMove={false}
        className={styles.popup}
      >
        <SvgActionsVisibleDisabledNormalXsmall fill="#fff" />
      </Popup>

      <MapAnalytics privateLocationId={privateLocation.id} />
    </Map>
  );
}

PrivateLocationMap.propTypes = {
  privateLocation: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    position: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }),
    original_position: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }),
    radius: PropTypes.number,
    repositionRateLimited: PropTypes.bool,
    regeneratePrivateLocationPath: PropTypes.string,
    deletePrivateLocationPath: PropTypes.string,
    distanceHidden: PropTypes.shape({
      label_key: PropTypes.string,
      value: PropTypes.string
    })
  }).isRequired
};

export default PrivateLocationMap;
